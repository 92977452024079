<template>
    <BCarousel ride="carousel" controls fade>
        <BCarouselSlide v-for="(carouseItem, index) in carouselItems" :key="index" :img-src="carouseItem.image" />
    </BCarousel>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { BCarousel, BCarouselSlide } from 'bootstrap-vue-next'

export default defineComponent({
    name: 'HomeCarouselComponent',
    components: {
        BCarousel,
        BCarouselSlide,
    },
    setup() {
        const carouselItems = ref([
            { image: 'https://picsum.photos/1024/480/?image=7' },
            { image: 'https://picsum.photos/1024/480/?image=8' },
            { image: 'https://picsum.photos/1024/480/?image=9' },
        ])

        return {
            carouselItems,
        }
    },
})
</script>