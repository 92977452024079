<template>
    <div class="about-bright-park">
        <h2 class="main-title mb-5 pb-4">المنتجات</h2>

        <BContainer>
            <BRow class="justify-content-center">
                <BCol v-for="(product, index) in products" :key="index" sm="12" md="6" lg="4" xl="3" class="mb-3">
                    <BCard class="curved-two-corners-reverse overflow-hidden border-0 shadow" img-src="https://picsum.photos/400/400/?image=41" img-alt="Image" overlay />
                </BCol>
            </BRow>

            <BButton variant="outline-primary" class="primary-outline-btn curved-two-corners w-25 my-5 py-3">مشاهدة الكل</BButton>
        </BContainer>
    </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { BContainer, BRow, BCol, BButton, BCard } from 'bootstrap-vue-next'

export default defineComponent({
    name: 'AboutBrightParkComponent',
    components: {
        BContainer,
        BRow,
        BCol,
        BCard,
        BButton,
    },
    setup() {
        const products = ref([0, 1, 2, 3]);

        return {
            products,
        }
    },
})
</script>
