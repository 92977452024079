import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createBootstrap } from 'bootstrap-vue-next'

import "bootstrap/dist/css/bootstrap.rtl.min.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import "./scss/styles.scss";

createApp(App).use(store).use(router).use(createBootstrap()).mount('#app')
