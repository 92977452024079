<template>
  <div class="home" id="home">
    <section class="home-carousel-section shadow">
      <HomeCarousel />
    </section>

    <section class="about-bright-park-section pt-5 mb-5 pb-5">
      <AboutBrightPark />
    </section>

    <section class="home-products-section pt-5 mb-5 pb-5">
      <HomeProducts />
    </section>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import HomeCarousel from '@/components/HomeCarousel.vue';
import AboutBrightPark from '@/components/AboutBrightPark.vue';
import HomeProducts from '@/components/HomeProducts.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    HomeCarousel,
    AboutBrightPark,
    HomeProducts,
  },
});
</script>
