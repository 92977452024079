<template>
    <div class="about-bright-park">
        <h2 class="main-title mb-5 pb-4">برايت بارك</h2>

        <BContainer>
            <BRow>
                <BCol lg="6" class="mb-4">
                    <BImg class="curved-two-corners" src="https://picsum.photos/1024/400/?image=41" fluid alt="Image" />
                </BCol>
                <BCol lg="6">
                    <p class="text-start">
                        برايت بارك هي شركة رائدة في صناعة الأجبان والألبان، حيث تمتلك خبرة تزيد عن 17 عاما في هذا
                        المجال، وتسعى الشركة إلى التوسع باستمرار وتقديم أفضل وأجود المنتجات لزبائنها.<br />
                        تتخصص برايت بارك في إنتاج مجموعة متنوعة من المنتجات التي تشمل الجبنة. اللبنة والحليب المنكه والسادة.<br />
                        تتميز منتجاتنا بالجودة العالية والطعم الرائع وهي نتاج تفانينا في اختيار أجود المكونات الطبيعية
                        والطازجة، واستخدام أحدث المعدات العالمية في مجال صناعة الألبان والأجبان، حيث تخضع المنتجات خلال
                        جميع مراحل الإنتاج إلى فحوصات دقيقة واختبارات لضمان سلامة وجودة المنتج....
                    </p>

                    <BButton variant="outline-primary" class="primary-outline-btn curved-two-corners w-50 py-3">اقرأ
                        المزيد...</BButton>
                </BCol>
            </BRow>
        </BContainer>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { BContainer, BRow, BCol, BImg, BButton } from 'bootstrap-vue-next'

export default defineComponent({
    name: 'AboutBrightParkComponent',
    components: {
        BContainer,
        BRow,
        BCol,
        BImg,
        BButton,
    },
})
</script>
