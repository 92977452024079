<template>
    <div id="appFooter" class="app-footer">
        <BContainer>
            <BRow class="justify-content-center">
                <BCol sm="12" md="4" lg="4" xl="3">
                    Bright Park Logo
                    <!-- <img class="footer-logo" src="../assets/bright-park-logo.png" /> -->
                </BCol>
                <BCol sm="12" md="8" lg="8" xl="9">
                    <BRow>
                        <BCol sm="12" md="6" lg="6" xl="3" class="mb-3">
                            <div class="footer-inner-container mb-5">
                                <h3 class="footer-list-title text-center text-md-start">برايت بارك</h3>
                                <ul class="footer-list text-center text-md-start">
                                    <li class="footer-list-item">
                                        <a class="footer-list-item-link" href="#">تعرف علينا</a>
                                    </li>
                                    <li class="footer-list-item">
                                        <a class="footer-list-item-link" href="#">المنتجات</a>
                                    </li>
                                    <li class="footer-list-item">
                                        <a class="footer-list-item-link" href="#">تواصل معنا</a>
                                    </li>
                                </ul>
                            </div>
                        </BCol>
                        <BCol sm="12" md="6" lg="6" xl="3" class="mb-3">
                            <div class="footer-inner-container">
                                <h3 class="footer-list-title text-center text-md-start">تواصل معنا</h3>
                                <ul class="footer-list text-center text-md-start">
                                    <li class="footer-list-item">
                                        <a class="footer-list-item-link" href="#">
                                            <i class="fa fa-phone footer-list-item-icon"></i>
                                            <span>+963 11 9090</span>
                                        </a>
                                    </li>
                                    <li class="footer-list-item">
                                        <a class="footer-list-item-link" href="#">
                                            <i class="fab fa-whatsapp footer-list-item-icon"></i>
                                            <span>+963 934 476 259</span>
                                        </a>
                                    </li>
                                    <li class="footer-list-item">
                                        <a class="footer-list-item-link" href="#">
                                            <i class="fa fa-envelope footer-list-item-icon"></i>
                                            <span>info@brightpark.net</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </BCol>
                        <BCol sm="12" md="6" lg="6" xl="3" class="mb-3">
                            <div class="footer-inner-container">
                                <h3 class="footer-list-title text-center text-md-start">العنوان</h3>
                                <ul class="footer-list text-center text-md-start">
                                    <li class="footer-list-item">ريف دمشق - سورية</li>
                                    <li class="footer-list-item">المدينة الصناعية</li>
                                </ul>
                            </div>
                        </BCol>
                        <BCol sm="12" md="6" lg="6" xl="3" class="mb-3">
                            <div class="footer-inner-container">
                                <h3 class="footer-list-title text-center text-md-start">تابعنا</h3>
                                <ul class="footer-list d-flex justify-content-center justify-content-md-start">
                                    <li class="footer-list-item">
                                        <a class="footer-list-item-link" href="#">
                                            <i class="fab fa-facebook footer-list-item-icon"></i>
                                        </a>
                                    </li>
                                    <li class="footer-list-item">
                                        <a class="footer-list-item-link" href="#">
                                            <i class="fab fa-instagram footer-list-item-icon"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </BCol>
                    </BRow>
                </BCol>
            </BRow>
        </BContainer>
        <div class="footer-copyright" dir="ltr">
            <h2 dir="ltr">&copy; 2024 Bright Park* Food Industries - All Rights Reserved</h2>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { BContainer, BRow, BCol } from 'bootstrap-vue-next'

export default defineComponent({
    name: 'AppFooterComponent',
    components: {
        BContainer,
        BRow,
        BCol,
    }
})
</script>
