<template>
    <BNavbar id="appHeader" class="app-header shadow" toggleable="lg" variant="light" v-b-color-mode="'light'" style="min-height: 80px" sticky="top">
        <BNavbarBrand to="/">
            Bright Park
            <!-- <img height="50" src="../assets/bright-park-logo.png" /> -->
        </BNavbarBrand>
        <BNavbarToggle target="nav-collapse" />
        <BCollapse id="nav-collapse" is-nav>
            <!-- Left aligned nav items -->
            <!-- <BNavbarNav></BNavbarNav> -->

            <!-- Right aligned nav items -->
            <BNavbarNav class="ms-auto mb-2 mb-lg-0">
                <template v-for="(link, index) in links" :key="index">
                    <BNavItemDropdown v-if="!!link.children" :class="{ 'active': isActive(link.to || '') }" right>
                        <template #button-content>
                            <em>{{ link.title }}</em>
                        </template>
                        <BDropdownItem v-for="(child, index) in link.children" :key="index" :to="link.to + child.to">{{ child.title }}</BDropdownItem>
                    </BNavItemDropdown>
                    <BNavItem v-else :to="link.to" :active="isActive(link.to || '')">{{ link.title }}</BNavItem>
                </template>
            </BNavbarNav>
        </BCollapse>
    </BNavbar>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { BNavbar, BNavbarBrand, BNavbarNav, BNavItem, BNavbarToggle, BCollapse, BNavItemDropdown, BDropdownItem, vBColorMode } from 'bootstrap-vue-next';
import { useRoute } from 'vue-router';

export default defineComponent({
    name: 'AppHeader',

    components: {
        BNavbar,
        BNavbarBrand,
        BNavbarNav,
        BNavItem,
        BNavbarToggle,
        BCollapse,
        BNavItemDropdown,
        BDropdownItem,
    },

    directives: {
        'b-color-mode': vBColorMode,
    },

    setup() {
        const route = useRoute()
        const isActive = (routePath) => route.fullPath.startsWith(routePath)

        const links = ref([
            { title: 'الرئيسية', to: '/home' },
            {
                title: 'المنتجات',
                to: '/products',
                children: [
                    { title: 'Test 1', to: '/test1' },
                    { title: 'Test 2', to: '/test2' },
                    { title: 'Test 3', to: '/test3' },
                ],
            },
            { title: 'تعرف علينا', to: '/about' },
            { title: 'تواصل معنا', to: '/contact' },
        ]);

        return {
            links,
            isActive,
        };
    },
});
</script>
